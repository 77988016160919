/**
 * Custom Responsive Button Hover states
 */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .gutters#{$infix} {
      margin-right: ($grid-gutter-width / -2);
      margin-left: ($grid-gutter-width / -2);
      > .col,
      > [class*="col-"] {
        padding-right: ($grid-gutter-width / 2);
        padding-left: ($grid-gutter-width / 2);
      }
    }
    .no-gutters#{$infix} {
      margin-right: 0;
      margin-left: 0;

      > .col,
      > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
