/**
 * Custom z-index states
 */

 @each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .layer-dom#{$infix} {
      z-index: 700 !important;
    }
    .layer-nav#{$infix} {
      z-index: 1000 !important;
    }
    .layer-sub-nav#{$infix} {
      z-index: 900 !important;
    }
    .layer-sub-overlay#{$infix} {
      z-index: 1000 !important;
    }
    .layer-overlay#{$infix} {
      z-index: 1200 !important;
    }
    .layer-negative#{$infix} {
      z-index: -1 !important;
    }
  }
}
