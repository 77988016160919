// Our Theme Var Overrides
@import "./styles/vars.module";

// Bootstrap library
@import "~bootstrap/scss/bootstrap";

// implement any custom vars
$custom-colors: () !default;
$custom-colors: map-merge(
  (
    "light-gray": #d8d8d8,
    "light-white": $lightwhite,
    "dark-gray": $darkgray
  ),
  $custom-colors
);
$colors: map-merge($custom-colors, $colors);
$all-colors: map-merge($theme-colors, $colors);

/**
 * SCSS Boostrap Modules
 * 
 * This File contains an include referenc for all custom SCSS modules built for the solar aschools public site.
 */

// Import our Botstrap Extensions
@import "./styles/modules/aspect_ratios";
@import "./styles/modules/indents";
@import "./styles/modules/typography";
@import "./styles/modules/hover_states";
@import "./styles/modules/bg_img";
@import "./styles/modules/responsive_bg_colors";
@import "./styles/modules/responsive_text_colors";
@import "./styles/modules/responsive_svg_fill";
@import "./styles/modules/responsive_svg_stroke";
@import "./styles/modules/responsive_box_arrow";
@import "./styles/modules/responsive_whitespace";
@import "./styles/modules/opacity";
@import "./styles/modules/layering";
@import "./styles/modules/responsive_borders";
@import "./styles/modules/responsive_gutters";
@import "./styles/modules/utilities";

html,
body {
  color: $darkgray;
}

*,
*::before,
*::after {
  color: inherit;
}
