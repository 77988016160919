@import "../_vars.module";

// stylelint-disable declaration-no-important

// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}-i#{$infix}-#{$size} {
          #{$prop}: -$length !important;
        }
        .#{$abbrev}t-i#{$infix}-#{$size},
        .#{$abbrev}y-i#{$infix}-#{$size} {
          #{$prop}-top: -$length !important;
        }
        .#{$abbrev}r-i#{$infix}-#{$size},
        .#{$abbrev}x-i#{$infix}-#{$size} {
          #{$prop}-right: -$length !important;
        }
        .#{$abbrev}b-i#{$infix}-#{$size},
        .#{$abbrev}y-i#{$infix}-#{$size} {
          #{$prop}-bottom: -$length !important;
        }
        .#{$abbrev}l-i#{$infix}-#{$size},
        .#{$abbrev}x-i#{$infix}-#{$size} {
          #{$prop}-left: -$length !important;
        }
      }
    }
  }
}
